<template>
  <div>
    <v-page background-color="#FFF" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t("restaurant.dineren-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t("restaurant.dineren") }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/nouba-watermerk.jpg')"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#282828" :background="require('@/assets/watermerk.svg?fill=#1F1F1F')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/diner met mooie en lokale.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t("restaurant.lokaleproducten-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t("restaurant.lokaleproducten") }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" x-large class="mt-6 rounded-0" style="color: black; font-weight: bold" href="https://restaurantnouba.nl/" target="_blank">{{ $t("restaurant.naarwebsite") }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";

export default {
  name: "Restaurant",
  components: {VPage, Footer}
}
</script>

<style scoped>

</style>