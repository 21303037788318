<template>
  <div>
    <v-page background-color="#F1EDED" class="py-16" no-auto-height>
      <v-container>
        <v-row class="text-center">
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("staff.header") }}</h1></v-col>
        </v-row>
        <fade-in-transition>
        <v-page no-auto-height background-color="#F0EDED">
          <v-row>
            <v-col class="shrink" cols="12" sm="4" xl="2">
              <v-img :src="require('@/assets/oscar.jpg')"  contain></v-img>
            </v-col>
            <v-col cols="12" sm="8" xl="6">
              <h1 class="c-primary font-weight-bold pb-3">{{ $t("staff.oscar") }}</h1>
              <h3 class="font-italic pb-5">{{ $t("staff.oscar-header") }}</h3>
              <p class="text-left">
                {{ $t("staff.oscar-text") }}
              </p>
            </v-col>
          </v-row>
        </v-page>
        </fade-in-transition>
        <fade-in-transition>
        <v-page no-auto-height background-color="#F0EDED">
          <v-row>
            <v-col cols="12" sm="8" xl="6" offset-xl="4" order="1" order-sm="0">
              <h1 class="c-primary font-weight-bold pb-3">{{ $t("staff.sven") }}</h1>
              <h3 class="font-italic pb-5">{{ $t("staff.sven-header") }}</h3>
              <p class="text-left">
                {{ $t("staff.sven-text") }}
              </p>
            </v-col>
            <v-col class="shrink" cols="12" sm="4" xl="2" order="0" order-sm="0">
              <v-img :src="require('@/assets/sven.jpg')"  contain></v-img>
            </v-col>
          </v-row>
        </v-page>
        </fade-in-transition>
        <fade-in-transition>
          <v-page no-auto-height background-color="#F0EDED">
            <v-row>
              <v-col class="shrink" cols="12" sm="4" xl="2">
                <v-img :src="require('@/assets/linda.jpg')"  contain></v-img>
              </v-col>
              <v-col cols="12" sm="8" xl="6">
                <h1 class="c-primary font-weight-bold pb-3">{{ $t("staff.linda") }}</h1>
                <h3 class="font-italic pb-5">{{ $t("staff.linda-header") }}</h3>
                <p class="text-left">
                  {{ $t("staff.linda-text") }}
                </p>
              </v-col>
            </v-row>
          </v-page>
        </fade-in-transition>
        <fade-in-transition>
        <v-page no-auto-height background-color="#F0EDED">
          <v-row>
            <v-col cols="12" sm="8" xl="6" offset-xl="4" order="1" order-sm="0">
              <h1 class="c-primary font-weight-bold pb-3">{{ $t("staff.nina") }}</h1>
              <p class="text-left">
                {{ $t("staff.nina-text") }}
              </p>
            </v-col>
            <v-col class="shrink" cols="12" sm="4" xl="2" order="0" order-sm="0">
              <v-img :src="require('@/assets/nina.jpg')"  contain></v-img>
            </v-col>
          </v-row>
        </v-page>
        </fade-in-transition>
        <fade-in-transition>
          <v-page no-auto-height background-color="#F0EDED">
            <v-row>
              <v-col class="shrink" cols="12" sm="4" xl="2">
                <v-img :src="require('@/assets/tom.jpg')"  contain></v-img>
              </v-col>
              <v-col cols="12" sm="8" xl="6">
                <h1 class="c-primary font-weight-bold pb-3">{{ $t("staff.tom") }}</h1>
                <h3 class="font-italic pb-5">{{ $t("staff.tom-header") }}</h3>
                <p class="text-left">
                  {{ $t("staff.tom-text") }}
                </p>
              </v-col>
            </v-row>
          </v-page>
        </fade-in-transition>
        <fade-in-transition>
        <v-page no-auto-height background-color="#F0EDED">
          <v-row>
            <v-col cols="12" sm="8" xl="6" offset-xl="4" order="1" order-sm="0">
              <h1 class="c-primary font-weight-bold pb-3">{{ $t("staff.eva") }}</h1>
              <h3 class="font-italic pb-5">{{ $t("staff.eva-header") }}</h3>
              <p class="text-left">
                {{ $t("staff.eva-text") }}
              </p>
            </v-col>
            <v-col class="shrink" cols="12" sm="4" xl="2" order="0" order-sm="0">
              <v-img :src="require('@/assets/eva.jpg')"  contain></v-img>
            </v-col>
          </v-row>
        </v-page>
        </fade-in-transition>
        <!--<v-row align="center" justify="center">
          <v-col cols="12" xl="8">
            <v-row>
              <v-col md="4" @mouseenter="tab = 0" :style="{backgroundColor: (tab === 0 ? 'white' : '#F1EDED'), cursor: 'pointer'}">
                <v-row>
                  <v-col><v-img :src="require('@/assets/oscar.jpg')"></v-img></v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col><h2 class="c-primary font-weight-bold">{{ $t("staff.oscar") }}</h2></v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col><h4 class="font-italic">{{ $t("staff.oscar-header") }}</h4></v-col>
                </v-row>
              </v-col>
              <v-col md="4" @mouseenter="tab = 1" :style="{backgroundColor: (tab === 1 ? 'white' : '#F1EDED'), cursor: 'pointer'}">
                <v-row>
                  <v-col><v-img :src="require('@/assets/sven.jpg')"></v-img></v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col><h2 class="c-primary font-weight-bold">{{ $t("staff.sven") }}</h2></v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col><h4 class="font-italic">{{ $t("staff.sven-header") }}</h4></v-col>
                </v-row>
              </v-col>
              <v-col md="4" @mouseenter="tab = 2" :style="{backgroundColor: (tab === 2 ? 'white' : '#F1EDED'), cursor: 'pointer'}">
                <v-row>
                  <v-col><v-img :src="require('@/assets/linda.jpg')"></v-img></v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col><h2 class="c-primary font-weight-bold">{{ $t("staff.linda") }}</h2></v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col><h4 class="font-italic">{{ $t("staff.linda-header") }}</h4></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="text-left">
              <v-tabs-items v-model="tab" class="pa-5">
                <v-tab-item style="white-space: pre-line">
                  {{ $t("staff.oscar-text") }}
                </v-tab-item>
                <v-tab-item style="white-space: pre-line">
                  {{ $t("staff.sven-text") }}
                </v-tab-item>
                <v-tab-item style="white-space: pre-line">
                  {{ $t("staff.linda-text") }}
                </v-tab-item>
              </v-tabs-items>

            </v-row>
          </v-col>
        </v-row>-->
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";
import FadeInTransition from "@/components/FadeInTransition";

export default {
  name: "Staff",
  components: {FadeInTransition, VPage, Footer},
  data() {
    return {
      tab: 0
    }
  }
}
</script>