<template>
  <div>
    <a id="ambiance" style="position:relative;top:-225px;"></a>
    <v-page background-color="#FFF" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t("brasserie.ambiance-header") }}</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t("brasserie.ambiance") }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn href="https://online.fliphtml5.com/vktcq/qtdc/" target="_blank" color="primary">Bekijk menukaart</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/101.jpg')"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="relaxen" style="position:relative;top:-225px;"></a>
    <v-page background-color="#282828" :background="require('@/assets/watermerk.svg?fill=#1F1F1F')"
            style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/78.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t("brasserie.relaxen-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t("brasserie.relaxen") }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="lunch" style="position:relative;top:-225px;"></a>
    <v-page background-color="#FFF" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">Lunch of Hightea</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                <p><b>Ontdek onze uitgebreide lunchopties en de openingstijden van het Poorter Hotel:</b></p>
                <p><b>Lunchseizoen - Maart t/m September:</b></p>
                <p>Dagen: Woensdag t/m Zondag | 12:00 - 16:00</p>
                Geniet van een heerlijke lunch in onze prachtige binnentuin of brasserie.
                Onze lunchopties bieden voor ieder wat wils, en we verwelkomen u graag voor een smakelijke maaltijd.
                <p></p>
                <p><b>Lunchseizoen - Oktober t/m Februari:</b></p>
                <p>Dagen: Vrijdag t/m Zondag | 12:00 - 16:00</p>
                <p>Hoewel ons restaurant in de wintermaanden beperktere openingsdagen heeft, blijven we u verwennen met heerlijke gerechten in onze knusse brasserie. De ideale plek om de koude dagen op te warmen met smaakvolle gerechten en warme drankjes.</p>
                In onze brasserie en lounge is er voor ieder wat wils. Of u nu langskomt voor een informele lunch, een zakelijke bijeenkomst, of een gezellig samenzijn met vriendinnen, bij ons bent u van harte welkom.
                Ontdek naast onze uitgebreide lunchkaart ook onze selectie aan heerlijke taartjes en verfrissende drankjes. Voor de ultieme verwennerij bieden we tevens de keuze tussen Luxury tea en Sweet tea, zowel voor individuen als groepen.
                Laat u betoveren door de diverse theesoorten en verrukkelijke lekkernijen die het Poorter Hotel te bieden heeft.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn href="https://online.fliphtml5.com/vktcq/qtdc/" target="_blank" color="primary">Bekijk menukaart</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img  :src="require('@/assets/high tea.jpg')" :aspect-ratio="16/9"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="afspreken" style="position:relative;top:-225px;"></a>
    <v-page background-color="#282828" :background="require('@/assets/watermerk.svg?fill=#1F1F1F')"
            style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :aspect-ratio="16/9" :src="require('@/assets/155.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">Plek om af te spreken</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                Onze lounge is een ideale plek om af te spreken. Wij beschikken over gratis WiFi, rondom parkeren is
                mogelijk en het Poorter hotel is midden in het centrum van vestingstad Brielle.
                De bar is open voor hotelgasten en voor mensen van buiten het hotel. In onze prachtige lounge
                stoelen kunt u genieten van;
                <ul>
                  <li>heerlijke cocktails</li>
                  <li>koffie en thee met heerlijk gebak keuze</li>
                  <li>prachtige wijnen</li>
                  <li>bites to share</li>
                  <li>small lunch dishes</li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="receptie" style="position:relative;top:-225px;"></a>
    <v-page background-color="#FFF" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">Receptie</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                Op zoek naar een unieke locatie om uw bedrijfsborrel, verjaardag of jubileum te organiseren !!
                Informeer naar de mogelijkheden in onze lounge en brasserie. Wij beschikken over een unieke
                locatie in een prachtig boutique hotel, waarbij wij diverse opstellingen naar uw wens kunnen
                organiseren onder de oudste stuc plafonds van Nederland. Enkele voorbeelden;
                <ul>
                  <li>Ontvangst met koffie/thee en gebak</li>
                  <li>Diverse drankjes waaronder ook cocktails</li>
                  <li>Diverse culinaire hapjes</li>
                </ul>
                <p>Kom gerust een keer langs, bel of mail ons, zodat wij met u de mogelijkheden kunnen bespreken en
                  passende offerte kunnen opmaken naar uw wensen.</p>
                <p>Onze hotelkamers staan gereed voor gasten
                  van verder weg, zodat ze na een gezellige avond de volgende dag weer uitgerust huiswaarts kunnen.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/receptie.jpg')" :aspect-ratio="16/9"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="brunch" style="position:relative;top:-225px;"></a>
    <v-page background-color="#282828" :background="require('@/assets/watermerk.svg?fill=#1F1F1F')"
            style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :aspect-ratio="16/9" :src="require('@/assets/brunch.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">Brunch</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                Heerlijk genieten van een verse brunch !! Uw verjaardag vieren, familie- of bedrijfsuitje onderbreken
                met een bezoek aan het Poorter hotel, informeer naar de mogelijkheden voor een heerlijke kleine
                lunch of brunch. Hierbij een voorbeeld van onze brunch;
                <ul>
                  <li>Koffie en thee naar keuze</li>
                  <li>Jus d’orange</li>
                  <li>Div. broodjes</li>
                  <li>Croissants en chocoladebroodjes</li>
                  <li>Vers beleg</li>
                  <li>Zoetigheden</li>
                  <li>Scrambled egg en gebakken eieren</li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn href="https://online.fliphtml5.com/vktcq/qtdc/" target="_blank" color="primary">Bekijk menukaart</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="huwelijk"  style="position:relative;top:-225px;"></a>
    <v-page background-color="#FFF" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">Huwelijk</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                Trouwplannen !!
                Natuurlijk bent u bij ons aan het juiste adres omtrent een heerlijke overnachting in onze bruidssuite,
                na jullie huwelijksdag.
                Maar ook kunt u in onze lounge en brasserie ook terecht voor uw huwelijksdag, trouw in 1 van de
                prachtige locaties die de gemeente Brielle rijk is, waaronder het oude stadhuis, raadzaal, de
                Synagoge of het kerkje in Zwartewaal en wij ontvangen u aansluitend in onze lounge met bv. een
                heerlijk glas cava of Champagne.
                Deze dag kunnen wij met uw wensen geheel invullen en bespreken. Enkele voorbeelden;
                <ul>
                  <li>Ontvangst van familie en vrienden vooraf</li>
                  <li>Ontvangst met cava of champagne na ceremonie</li>
                  <li>Op locatie mogelijk in overleg</li>
                  <li>Koffie/thee met bruidstaart aansnijden</li>
                  <li>Toasten met diverse drankjes en culinaire hapjes</li>
                  <li>Mogelijkheid tot totaal arrangement om onze 11 unieke hotelkamers af te huren</li>
                  <li>Gezamenlijk ontbijt na overnachting</li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :aspect-ratio="16/9" :src="require('@/assets/huwelijk.jpg')"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="vergader"  style="position:relative;top:-225px;"></a>
    <v-page background-color="#282828" :background="require('@/assets/watermerk.svg?fill=#1F1F1F')"
            style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/vergaderen.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">Vergadermogelijkheden</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                Op zoek naar een unieke plek om te vergaderen !!
                Vergaderen onder de oudste stucplafonds uit 1600 !!
                Wij bieden u de mogelijkheid om te vergaderen in een unieke setting, wij toveren onze lounge om tot
                vergaderruimte welke geheel afsluitbaar is.
                Samen met u zullen wij uw wensen bespreken en gereed hebben op de dag dat u komt.
                Informeer naar de mogelijkheden. Enkele voorbeelden;
                <ul>
                  <li>U-vorm vergader tafel mogelijk tot max. 12/14 personen</li>
                  <li>WIFI gratis</li>
                  <li>Meetings in theateropstelling tot max. 30 personen</li>
                  <li>Beamer en scherm ter beschikking</li>
                  <li>Lunch mogelijkheden tussendoor in onze brasserie</li>
                  <li>Afsluiting met borrel en hapjes in onze brasserie</li>
                  <li>Mogelijk om dagelijks in onze lounge een kleine meeting te houden</li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <a id="nieuwjaar" style="position:relative;top:-225px;"></a>
    <v-page background-color="#FFF" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">Kerst- en nieuwjaarsborrels</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                Richting de warme dagen van december is het weer fijn om samen het jaar af te sluiten of het
                nieuwe jaar in te luiden met een bedrijfs- of familie borrel in 1 van de oudste panden van Brielle.
                Het Poorter boutique hotel beschikt over 2 aparte ruimtes om bv. In onze brasserie met zijn
                prachtige bar een borrel te organiseren tot maximaal 30 personen.
                Graag willen wij met u in overleg om te kijken naar uw wensen en onze mogelijkheden om samen tot
                een prachtige middag of avond te komen. Enkele mogelijkheden;
                <ul>
                  <li>Koffie/thee met gebak keuze</li>
                  <li>Borrelbijeenkomst met diverse culinaire hapjes</li>
                  <li>Mogelijkheid tot overnachting voor gasten van verder af</li>
                  <li>Meedenken betreffende actieve uitstapjes</li>
                  <li>Unieke locatie</li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/close-up-view-of-two-glasses-with-champagne-at-new-GYH3N5C.jpg')"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";

export default {
  name: "Brasserie",
  components: {VPage, Footer}
}
</script>

<style scoped>

</style>
