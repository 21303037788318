<template>
  <div>
    <v-page background-color="#F1EDED" class="py-16" no-auto-height>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <v-responsive :aspect-ratio="16/9">
              <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/yRGf8T1YPPs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </v-responsive>
          </v-col>
          <v-col cols="12" md="6">
            <v-responsive :aspect-ratio="16/9">
              <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/hJadqo8owgc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";

export default {
  name: "Staff",
  components: {VPage, Footer},
  data() {
    return {
      tab: 0
    }
  }
}
</script>

<style scoped>

</style>
