<template>
  <div>
    <v-page background-color="#FFF" class="py-16 text-center" no-auto-height>
      <v-container>
        <v-row>
          <v-col class="font-weight-bold" style="font-size: 1rem;text-transform:uppercase;">{{ $t('overons.header') }}</v-col>
        </v-row>
        <v-row>
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t('overons.intro-header') }}</h1></v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" lg="8" xl="6">
            {{ $t('overons.intro') }}
          </v-col>
        </v-row>

      </v-container>
    </v-page>
    <v-page background-color="#282828" :background="require('@/assets/watermerk.svg?fill=#1F1F1F')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img :src="require('@/assets/132.jpg')" :aspect-ratio="16/9"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t('overons.waarompoorter-header') }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t('overons.waarompoorter') }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";

export default {
  name: "Brasserie",
  components: {VPage, Footer}
}
</script>

<style scoped>

</style>